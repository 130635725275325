.JobTable {
    min-width: 650;
    width: 100%;
}

.JobTableHead {
    background: #e02527;
    color: #fff;
    font-weight: 700;
}

.JobTableBody {
    background: #e5e5e5;
}

table.jobDashboard {
    border-spacing: 0;
    border: px solid #ededed;
}
table.jobDashboard tr:last-child td {
    border-bottom: 0;
}
table.jobDashboard th,
table.jobDashboard td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid #ededed;
    border-right: 1px solid #ededed;
    position: relative;
}
table.jobDashboard th:last-child,
table.jobDashboard td:last-child {
    border-right: 0;
}

table.jobDashboard th::before {
    position: absolute;
    right: 15px;
    top: 16px;
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
}
table.jobDashboard th.sort-asc::before {
    border-bottom: 5px solid #22543d;
}
table.jobDashboard th.sort-desc::before {
    border-top: 5px solid #22543d;
}
input.jobDashboard {
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-shadow: none;
    width: 100%;
}
