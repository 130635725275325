.jobDashboard-errormessage {
    background-color: #e02527d0;
    align-items: center;
    font-size: 15pt;
    font-weight: 500;
    color: #fff;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.jobDashboard-header {
    background: #333;
    color: #fff;
    text-align: center;
    padding: 10px;
    width: inherit;
}
