.login-background {
    background: linear-gradient(225deg, #191919, #000);
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: absolute;
    color: white;
    padding: 10px;
}

.login-content {
    padding: 50px;
    max-width: 325px;
    margin: auto;
}

.login-logo {
    width: 80px;
}

.form-control.login-input-text {
    background: none;
    border: none;
    border-bottom: 1px solid RGBA(255, 255, 255, 0.1);
    color: white;
}

.form-control.login-input-text.login-input-text-short {
    width: 60%;
}
