.navbar.Toolbar {
    padding: 0px;
}

.Toolbar.fixed-top {
    position: fixed;
}

.navbar {
    background: rgb(25, 25, 25);
    background: linear-gradient(225deg, rgba(25, 25, 25, 1) 0%, rgba(0, 0, 0, 1) 100%);
    margin-bottom: 20px;
    border-bottom: 1px solid RGBA(255, 255, 255, 0.1);
    z-index: 1040 !important;
}

.navbar-brand {
    display: inline-block;
    background-color: #e92a2a;
    width: 200px;
    white-space: nowrap;
}

.navbar-brand .navbar-dark {
    color: #fff;
}

.Toolbar .mb-icon {
    text-decoration: none;
    height: 50px;
    align-content: center;
    margin: 0px;
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}

.navbar-expand-lg .navbar-nav .nav-link.nav-link-profile {
    padding-right: 3rem !important;
    padding-left: 1rem !important;
}

.navbar-expand-lg .navbar-nav .nav-link.nav-link-right {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}

.navbar-right {
    margin-right: -3rem;
}

.Toolbar .dropdown-item {
    padding: 0.5rem 1rem !important;
}

.Toolbar .dropdown-toggle {
    cursor: pointer;
    padding: 0.5rem 1rem;
    padding-right: 3rem;
    padding-left: 3rem;
    display: block;
}

.Toolbar .dropdown-toggle.no-caret::after {
    display: none;
}

.font-weight-bold {
    font-weight: 700 !important;
}

.feather-icon {
    margin-right: 20px;
    color: white;
}

.Toolbar .dropdown-menu {
    border: none;
}

.Toolbar .dropdown-menu-dark {
    background-color: #1f1f1f;
    box-shadow: 0px 0px 5px RGBA(0, 0, 0, 0.5);
}

.Toolbar .dropdown-menu-dark .dropdown-item {
    color: rgba(255, 255, 255, 0.5);
}

.Toolbar .dropdown-menu-dark .dropdown-item:hover,
.Toolbar .dropdown-menu-dark .dropdown-item:focus {
    background-color: #141414;
}

.Toolbar .dropdown-divider-dark {
    border-top: 1px solid #121212;
}

.Toolbar .dropdown-menu.dropdown-menu-right {
    right: 10px;
    left: auto;
    bottom: auto;
}

.Toolbar .dropdown-separator {
    border-top: 2px solid #3d3d3d;
}

.circle {
    height: 20px;
    width: 20px;
    background-color: #e92a2a;
    border-radius: 50%;
    display: inline-block;
    line-height: 1rem;
}

.nav-icon {
    font-size: 2rem;
    margin: 10px;
    cursor: pointer;
}

.side-bar {
    top: 50px;
    height: 100vh;
    width: 200px;
    transition: left 0.3s ease-in-out;
}

@media (min-width: 768px) {
    .mobile-hide-dd {
        display: visible;
    }
}

@media (max-width: 576px) {
    .navbar-brand {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        font-size: 1rem;
        background-color: #e92a2a;
        width: 60px;
    }
    .desktopOnly {
        display: none;
    }
}

@media (max-width: 768px) {
    .desktopOnly {
        display: none;
    }
    .mobile-hide-dd {
        display: none;
    }
}
