@import './theme.scss';

.bg-green-highlight {
    background-color: theme-color('greenHighlight') !important;
}

.bg-yellow-highlight {
    background-color: theme-color('yellowHighlight') !important;
}

.bg-purple-highlight {
    background-color: theme-color('purpleHighlight') !important;
}

.bg-red-highlight {
    background-color: theme-color('redHighlight') !important;
}

tr.bgDanger25 > td {
    border-top: 1px solid rgba(theme-color('danger'), 25%) !important;
    border-bottom: 1px solid rgba(theme-color('danger'), 25%) !important;
    background-color: rgba(theme-color('danger'), 10%) !important;
}

td.bgDanger25 {
    border-top: 1px solid rgba(theme-color('danger'), 25%) !important;
    border-bottom: 1px solid rgba(theme-color('danger'), 25%) !important;
    background-color: rgba(theme-color('danger'), 10%) !important;
}

tr.bgWarning25 > td {
    border-top: 1px solid rgba(theme-color('warning'), 25%) !important;
    border-bottom: 1px solid rgba(theme-color('warning'), 25%) !important;
    background-color: rgba(theme-color('warning'), 10%) !important;
}

td.bgWarning25 {
    border-top: 1px solid rgba(theme-color('warning'), 25%) !important;
    border-bottom: 1px solid rgba(theme-color('warning'), 25%) !important;
    background-color: rgba(theme-color('warning'), 10%) !important;
}

tr.bgSuccess25 > td {
    border-top: 1px solid rgba(theme-color('success'), 25%) !important;
    border-bottom: 1px solid rgba(theme-color('success'), 25%) !important;
    background-color: rgba(theme-color('success'), 10%) !important;
}

td.bgSuccess25 {
    border-top: 1px solid rgba(theme-color('success'), 25%) !important;
    border-bottom: 1px solid rgba(theme-color('success'), 25%) !important;
    background-color: rgba(theme-color('success'), 10%) !important;
}
