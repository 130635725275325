table.marginReportTable th::before {
    position: absolute;
    right: 15px;
    top: 16px;
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
}
table.marginReportTable th.sort-asc::before {
    border-bottom: 5px solid #22543d;
}
table.marginReportTable th.sort-desc::before {
    border-top: 5px solid #22543d;
}
