.mb-question-selector {
    cursor: pointer;
    padding: 10px;
    border-bottom: 4px solid RGBA(0, 0, 0, 0);
    transition: background-color 0.5s;
    flex: 1;
}

.mb-question-selector:hover {
    background-color: RGBA(0, 0, 0, 0.05);
}

.mb-question-selector-hovered.above::before {
    content: ' ';
    display: block;
    margin-bottom: 15px;
    height: 25px;
    border-radius: 5px;
    background-color: RGBA(0, 0, 0, 0.05);
    border: 2px dashed RGBA(0, 0, 0, 0.25);
}

.mb-question-selector-hovered.below::after {
    content: ' ';
    display: block;
    margin-top: 15px;
    height: 25px;
    border-radius: 5px;
    background-color: RGBA(0, 0, 0, 0.05);
    border: 2px dashed RGBA(0, 0, 0, 0.25);
}

.mb-question-selector-selected {
    background: RGBA(0, 0, 0, 0.1);
}
