html {
    height: 100%;
    font-size: 0.75rem;
}

body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #212529;
    bottom: 0;
    height: 100%;
    background-color: #f4f4f4 !important;
}

div#root {
    height: 100%;
}

.text-mblight {
    color: RGBA(0, 0, 0, 0.25);
}

.bebas {
    font-family: bebas-neue, sans-serif;
}
