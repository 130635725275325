.mb-survey {
    background: #fff;
    padding: 30px;
    min-height: 600px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 2rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    max-height: 650px;
    overflow-y: scroll;
}

.mb-survey-1 {
    position: relative;
    height: 500px;
}

.mb-survey h3 {
    font-family: Georgia, Didot, serif;
    color: #283572;
}

.mb-survey h3 .form-control {
    font-family: Georgia, Didot, serif;
    color: #283572;
}

.mb-input-radio {
    border: 1px solid RGBA(0, 0, 0, 0.2);
    border-radius: 3px;
}

.question-container {
    max-width: 500px;
}
.mb-survey-loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 10px;
}

.mb-survey-loading-bar {
    height: 10px;
    background: #3cf5a4;
    transition-property: width;
    transition-duration: 0.5s;
    transition-delay: 0s;
}

.next-step-1 {
    right: 25px;
}

.next-step-2 {
    right: 25px;
}

.btn-text-input {
    cursor: text !important;
    user-select: auto !important;
    -webkit-user-select: auto !important;
}

.btn-text-input:hover {
    color: #000;
    background-color: #fff;
    border-color: #dee2e6;
}

.btn-text-input:focus,
.btn-text-input:active {
    color: #000 !important;
    background-color: #fff !important;
    border-color: #dee2e6 !important;
}

input[type='text'].mb-button-input-text {
    background-color: #007bff;
    border-color: #007bff;
    color: white;
    text-align: center;
    height: 50px;
}

input[type='text'].mb-button-input-text::placeholder {
    color: RGBA(255, 255, 255, 0.5);
    opacity: 1;
}

@media (max-width: 992px) {
    .next-step-1 {
        bottom: 25px;
    }
    .back-step-1 {
        bottom: 25px;
    }
}

@media (max-width: 1200px) {
    .next-step-2 {
        bottom: 25px;
    }
    .back-step-2 {
        bottom: 25px;
    }
}
