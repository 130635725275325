.form-control.quick-search {
    background: transparent;
    border: 1px solid RGBA(255, 255, 255, 0.1);
    color: white;
}

.form-control.quick-search:focus {
    background: transparent;
    color: white;
}
