.chart-container {
    margin: 2em;
    width: 95%;
    align-self: center;
}

.trendGraphTitle {
    color: black;
    font-size: 1.5em;
    font-weight: bold;
    padding: 2px;
    margin: 2px;
    text-align: center;
}
