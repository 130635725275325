.Affiliate .col-sm-4 {
    padding-right: 6px;
    padding-left: 6px;
}

.Affiliate {
    font-size: 0.9rem !important;
}
.Affiliate .table {
    margin-bottom: 0px !important;
}
