.mb-pill {
    font-size: 0.8rem;
    padding: 0px 10px;
    margin: 3px 2px;
    display: inline-block;
    cursor: default;
    border: 1px solid;
    border-radius: 5px;
    /* width: 20%; */
    align-items: center;
    text-align: center;
}
